import { AzureInsightsConstants } from '../app/modules/shared/constants/monitoring/azure-insights.constants';

export const environment = {
  production: false,
  develop: true,
  environment: 'DEV',
  enableProdMode: true,
  gppUrl: 'https://myprofile.test.account.bbraun.com/',
  backendUrl: 'https://fem.dev.bbraun.io/api',
  postLogoutRedirectUri: 'https://fem.dev.bbraun.io/',
  api: 'https://fem.dev.bbraun.io/api',
  deviceFleetManagementURL: 'https://portal.iomtdev.bbraun.io/device-details/',
  msalConfig: {
    auth: {
      clientId: '72d8758c-33d1-4fb5-956b-c0d8b3e2dcd3'
    }
  },
  apiConfig: {
    scopes: [
      'openid',
      'profile',
      'offline_access',
      'https://test.account.bbraun.com/113a87a8-2a63-4f39-90be-9e47640e9155/read'
    ],
    uri: 'https://fem.dev.bbraun.io/api/*'
  },
  b2cPolicies: {
    names: {
      signUpSignIn: 'B2C_1A__SIGNUP_SIGNIN_MFA'
    },
    authorities: {
      signUpSignIn: {
        authority:
          'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/B2C_1A__SIGNUP_SIGNIN_MFA/'
      }
    },
    authorityDomain: 'test.account.bbraun.com'
  },
  monitoring: {
    enable: true,
    azureInsightsKey: AzureInsightsConstants.DEV_KEY
  }
};
