import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HTTP_STATUS_CODES } from '@bbraun/cortex/number-input';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ROUTES } from '../../constants/routes/routes.constants';
import { SnackbarService } from '../../services/snackbar/snackbar.service';
import { isBadRequestError, isInternalServerError } from '../../utils/errors/general.utils';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly snackbarServiceHelper: SnackbarService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (ev: HttpEvent<any>) => ev,
        (err) => {
          if (!(err instanceof HttpErrorResponse)) {
            return;
          }

          if (err.status === HTTP_STATUS_CODES.FORBIDDEN) {
            this.router.navigateByUrl(ROUTES.ERROR.FORBIDDEN);
            return;
          }

          if (this.checkForGettingFeaturesRequest(err)) {
            this.deviceDetailsAndOfflineManagementExceptions(err);
            return;
          }

          this.errorPageRedirect(err);
        }
      )
    );
  }

  /**
   * Redirect to the error page with the necessary data
   */
  private errorPageRedirect(err: HttpErrorResponse): void {
    if (err.status === HTTP_STATUS_CODES.NOT_FOUND && !this.checkForGettingFeaturesRequest(err)) {
      this.router.navigateByUrl(ROUTES.ERROR.NOT_FOUND);
      return;
    }

    if (isInternalServerError(err)) {
      this.router.navigateByUrl(ROUTES.ERROR.SERVER_ERROR);
    }
  }

  private deviceDetailsAndOfflineManagementExceptions(err: HttpErrorResponse): void {
    if (isInternalServerError(err)) {
      this.snackbarServiceHelper.updateInternalErrorSnackbars();
      return;
    }

    if (isBadRequestError(err)) {
      this.snackbarServiceHelper.updateBadRequestSnackbars();
    }
  }

  private checkForGettingFeaturesRequest(err: HttpErrorResponse): boolean {
    // We are not getting any devices
    if (err.url!.indexOf('/devices/') < 0) {
      return false;
    }

    return err.url!.indexOf('/features') > 0 || err.url!.indexOf('/pending-features') > 0;
  }
}
