<ng-container *transloco="let t">
  <app-header [title]="t('overview.title')"></app-header>
</ng-container>

<div
  class="error-pages-container d-flex align-items-center justify-content-center flex-column"
  data-cy="error-not-found-container"
  *transloco="let err; read: 'errors'"
>
  <cx-message class="message-no-height">
    <cx-message-title>{{ err('notFound.title') }}</cx-message-title>
    <cx-message-image [imageSrc]="'assets/images/error-page-not-found.svg'" />
    <cx-message-text>
      <div class="text-center">
        {{ err('notFound.description') }}
        <br />
        {{ err('notFound.pageRemovedOrNotExist') }}
      </div>
    </cx-message-text>
    <cx-message-button
      [buttonText]="err('goToHome')"
      (click)="goToHome()"
    />
  </cx-message>
</div>
