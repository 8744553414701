import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-page-not-found',
  templateUrl: './error-page-not-found.component.html'
})
export class ErrorPageNotFoundComponent {
  constructor(private readonly router: Router) {}

  goToHome(): void {
    this.router.navigateByUrl(``);
  }
}
