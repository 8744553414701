import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { USERS_DATA_TABLE } from '../../constants/table/table.constants';
import { UserRoles } from '../../enums/user-roles.enum';
import { PagingResponse } from '../../interfaces/paging-response.interface';
import { UpdatePermissionsRequest } from '../../interfaces/user-permissions/update-permissions-request.interface';
import { UserPermissionsFilters } from '../../interfaces/user-permissions/user-permissions-filters.interface';
import { UserData } from '../../interfaces/users/user-data.interface';
import { UsersData } from '../../interfaces/users-data.interface';

@Injectable()
export class UserPermissionsService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Get users based on page, sorting and content
   *
   * @param page    - Number of the page
   * @param sort    - Defines if the retrieved result is sorted
   * @param filters - Term and role filters
   * @param size    - Number of elements to retrieve
   */
  getUsers(
    page: number,
    sort: string,
    filters: UserPermissionsFilters,
    size = 0
  ): Observable<PagingResponse<UserData>> {
    let params = new HttpParams();

    if (page !== USERS_DATA_TABLE.pageIndex) {
      params = params.append('page', page.toString());
    }

    if (size !== USERS_DATA_TABLE.pageSize) {
      params = params.append('size', size.toString());
    }

    if (sort !== USERS_DATA_TABLE.sort) {
      params = params.append('sort', sort);
    }

    if (filters.term) {
      params = params.append(filters.nameType, filters.term);
    }

    if (filters.role && filters.role !== UserRoles.ALL) {
      params = params.append('role', filters.role.toString());
    }

    return this.http.get<PagingResponse<UserData>>(`${environment.api}/admin-panel/users`, {
      params
    });
  }

  /**
   * Get user data based on uuid
   *
   * @param uuid - identifier of a specific user
   */
  getUser(uuid: string): Observable<UsersData> {
    return this.http.get<UsersData>(`${environment.api}/admin-panel/users/${uuid}`);
  }

  /**
   * Updates user data based on uuid
   *
   * @param uuid - identifier of a specific user
   * @param data - new data which will be updated
   */
  updateUser(uuid: string, data: UpdatePermissionsRequest): Observable<UsersData> {
    return this.http.post<UsersData>(`${environment.api}/admin-panel/users/${uuid}`, data);
  }

  getUsersSync(): Observable<boolean> {
    return this.http.post<boolean>(`${environment.api}/admin-panel/users/sync`, null);
  }

  getAllProductIDs(): Observable<Array<string>> {
    return this.http.get<Array<string>>(`${environment.api}/devices/product-ids`);
  }

  getUserProductIdsCount(): Observable<number> {
    return this.http.get<number>(`${environment.api}/devices/product-ids/count`);
  }
}
