import { TABLE_DEFAULT_PAGE_SIZE } from '@bbraun/cortex/table';
import { getStorageItem, hasStorageItem } from '@bbraun/helpex/src/lib/utils';

import { TableData } from '../../interfaces/table-data.interface';
import { DEFAULT_TERM } from '../default/default.constants';
import { LOCAL_STORAGE } from '../local-storage/local-storage.constants';

export const OVERVIEW_TABLE: TableData = {
  sort: 'customer.name,desc',
  term: DEFAULT_TERM,
  pageSize: (hasStorageItem(LOCAL_STORAGE.TABLE_OVERVIEW_PAGE_SIZE, localStorage)
    ? getStorageItem(LOCAL_STORAGE.TABLE_OVERVIEW_PAGE_SIZE, localStorage)
    : TABLE_DEFAULT_PAGE_SIZE) as number,
  pageIndex: 0,
  activeSort: 'customer',
  sortDirection: 'desc',
  defaultSorting: {
    id: 'customer',
    start: 'desc'
  },
  columnOrder: [
    'customer',
    'country',
    'productId',
    'serialNumber',
    'createdAt',
    'lastUpdatedAt',
    'actions'
  ]
};

export const DEVICE_CONFIRM_DIALOG_TABLE = {
  columnOrder: ['feature', 'settings', 'licenceType', 'expiryDate']
};

export const DEVICE_FEATURE_TABLE = {
  sort: 'name,desc',
  term: DEFAULT_TERM,
  activeSort: 'name',
  sortDirection: 'desc'
};

export const HISTORY_OVERVIEW_TABLE = {
  columnOrder: ['createdAt', 'createdBy', 'version', 'actions']
};

export const HISTORY_DEVICE_FEATURE_TABLE = {
  columnOrder: ['feature', 'entitled', 'licenceType', 'expiryDate']
};

export const commonColumnDefinitions = [
  {
    columnDef: 'entitled',
    columnLabel: 'deviceDetails.features.table.entitled',
    projectionSelector: 'entitled',
    sortable: false,
    columnMaxWidth: '8.1%'
  },
  {
    columnDef: 'status',
    columnLabel: 'deviceDetails.features.table.status',
    projectionSelector: 'status',
    sortable: false,
    columnMaxWidth: '11%'
  },
  {
    columnDef: 'licenceType',
    columnLabel: 'deviceDetails.features.table.licenceType',
    projectionSelector: 'licenceType',
    sortable: false,
    columnMaxWidth: '9.6%'
  },
  {
    columnDef: 'expiryDate',
    columnLabel: 'deviceDetails.features.table.expiryDate',
    projectionSelector: 'expiryDate',
    sortable: false,
    columnMaxWidth: '8.5%'
  },
  {
    columnDef: 'usages',
    columnLabel: 'deviceDetails.features.table.usages',
    projectionSelector: 'usages',
    sortable: false,
    columnMaxWidth: '10.2%'
  }
];

export const historyColumnDefinitions = [
  {
    columnDef: 'feature',
    columnLabel: 'deviceDetails.features.table.feature',
    projectionSelector: 'feature',
    sortable: false,
    columnMaxWidth: '59.5%%'
  },
  {
    columnDef: 'entitled',
    columnLabel: 'deviceDetails.features.table.entitled',
    projectionSelector: 'entitled',
    sortable: false,
    columnMaxWidth: '12.5%'
  },
  {
    columnDef: 'licenceType',
    columnLabel: 'deviceDetails.features.table.licenceType',
    projectionSelector: 'licenceType',
    sortable: false,
    columnMaxWidth: '14%'
  },
  {
    columnDef: 'expiryDate',
    columnLabel: 'deviceDetails.features.table.expiryDate',
    projectionSelector: 'expiryDate',
    sortable: false,
    columnMaxWidth: '14%'
  }
];

export const historyOverviewColumnDefinitions = [
  {
    columnDef: 'createdAt',
    columnLabel: 'history.createdAt',
    projectionSelector: 'createdAt',
    sortable: false,
    columnMaxWidth: '16%'
  },
  {
    columnDef: 'createdBy',
    columnLabel: 'history.createdBy',
    projectionSelector: 'createdBy',
    sortable: false,
    columnMaxWidth: '49.4%'
  },
  {
    columnDef: 'version',
    columnLabel: 'history.payloadVersion',
    projectionSelector: 'version',
    sortable: false,
    columnMaxWidth: '20%'
  },
  {
    columnDef: 'actions',
    columnLabel: 'history.actions',
    projectionSelector: 'actions',
    sortable: false,
    columnMaxWidth: '15%'
  }
];

export const USERS_DATA_TABLE: TableData = {
  sort: 'fullName,asc',
  term: DEFAULT_TERM,
  pageSize: TABLE_DEFAULT_PAGE_SIZE,
  pageIndex: 0,
  activeSort: 'fullName',
  sortDirection: 'asc',
  defaultSorting: {
    id: 'fullName',
    start: 'asc'
  },
  columnOrder: ['fullName', 'role', 'countries', 'productIds', 'actions'],
  totalElements: 0
};

export const LICENSE_AVAILABILITY: TableData = {
  sort: 'productId,asc',
  term: DEFAULT_TERM,
  pageSize: TABLE_DEFAULT_PAGE_SIZE,
  pageIndex: 0,
  activeSort: 'productId',
  sortDirection: 'asc',
  defaultSorting: {
    id: 'productId',
    start: 'asc'
  },
  columnOrder: ['productId', 'payPerUseLicense', 'perpetualLicense', 'timedLicense', 'actions'],
  totalElements: 0
};
