import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { DeviceFeatureModuleChange } from '../../interfaces/device/device-feature-module-change.interface';
import { DevicePreflightRequestResponse } from '../../interfaces/device/device-preflight-request-response.interface';
import { SnackBarNotification } from '../../interfaces/device/snackbar-notification.interface';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  notify = new BehaviorSubject<SnackBarNotification>({
    type: 'info',
    text: ''
  });

  updateDeviceFeaturesSnackbars(
    response: DevicePreflightRequestResponse<DeviceFeatureModuleChange>
  ): void {
    if (response.hardwareRequiredUpdated) {
      this.notify.next({
        type: 'info',
        text: 'deviceEdit.snackbars.hardwareRequired'
      });
    }

    const updated =
      (response.dependenciesFeatures?.length ?? 0) + (response.updatedFeatures?.length || 0);

    this.notify.next({
      type: 'success',
      text: 'deviceEdit.snackbars.updateSuccessful',
      numberOfFeatures: updated
    });
  }

  updateInternalErrorSnackbars(): void {
    this.notify.next({
      type: 'error',
      text: 'common.snackbars.updateInternalError'
    });
  }

  updateBadRequestSnackbars(): void {
    this.notify.next({
      type: 'error',
      text: 'common.snackbars.updateBadRequest'
    });
  }

  // @TODO: Restore this when custom parameters int he error messages are needed
  /*private translateParams(params: Array<string> = []) {
    const formattedParams = handleParams(params);

    Object.keys(formattedParams).forEach((key) => {
      if (!isNaN(formattedParams[key])) {
        return;
      }

      formattedParams[key] = this.translocoService.translate(
        `common.${formattedParams[key].toLowerCase()}`
      );
    });

    return formattedParams;
  }*/
}
