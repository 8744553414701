<ng-container *transloco="let t">
  <app-header [title]="t('overview.title')"></app-header>

  <div
    class="max-width ml-auto mr-auto error-pages-container d-flex align-items-center justify-content-center flex-column"
    data-cy="error-internal-server-container"
    *transloco="let err; read: 'errors'"
  >
    <cx-message class="message-no-height">
      <cx-message-title>{{ err('internalServerError.title') }}</cx-message-title>
      <cx-message-image [imageSrc]="'assets/images/error-page-forbidden.svg'" />
      <cx-message-text class="message">
        <div class="text-center">
          {{ err('internalServerError.description') }}
        </div>
      </cx-message-text>
      <cx-message-button
        *ngIf="!hideBtn"
        buttonText="{{ t('errors.goToHome') }}"
        (click)="goToHome()"
      />
    </cx-message>
  </div>
</ng-container>
