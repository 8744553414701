import { Inject, Injectable } from '@angular/core';

import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService
} from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { TokenClaims } from '@azure/msal-common/dist/account/TokenClaims';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {
  private account: AccountInfo | null = null;
  private isAuthorized = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private readonly msalGuardConfig: MsalGuardConfiguration,
    private readonly authService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService
  ) {}

  handleUserAuthentication() {
    this.handleSuccessfulLogin();
    this.authService.instance.enableAccountStorageEvents();
  }

  logout(): void {
    this.authService.logout({
      idTokenHint: this.account?.idToken
    });
  }

  get idTokenClaims(): TokenClaims {
    return this.account?.idTokenClaims as TokenClaims;
  }

  private handleSuccessfulLogin(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        )
      )
      .subscribe((result: EventMessage) => {
        const payload: AuthenticationResult = <any>result.payload;
        this.authService.instance.setActiveAccount(payload.account);
        this.account = {
          ...payload.account,
          idToken: payload.idToken
        };
      });
  }
}
