import { Component, OnInit } from '@angular/core';

import { environment } from '../environments/environment';
import { AzureInsightsHelperService } from './modules/shared/services/azure-insights-helper/azure-insights-helper.service';
import { AuthenticateService } from './modules/shared/services/user/authenticate/authenticate.service';
import { AuthorizeService } from './modules/shared/services/user/authorize/authorize.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  isDevMode = false;

  constructor(
    private readonly authenticateService: AuthenticateService,
    private readonly authorizeService: AuthorizeService,
    private readonly azureInsightsHelper: AzureInsightsHelperService
  ) {}

  ngOnInit(): void {
    this.isDevMode = environment.develop;
    this.authenticateService.handleUserAuthentication();
    this.authorizeService.handleUserAuthorization();
    this.azureInsightsHelper.handleMonitoring();
  }
}
