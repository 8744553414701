import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CxMenuModule } from '@bbraun/cortex/carousel';
import { CxDialogModule } from '@bbraun/cortex/dialog';
import { CxHeaderModule } from '@bbraun/cortex/header';
import { CxProfileDropdownComponent } from '@bbraun/cortex/profile-dropdown';
import { TranslocoRootModule } from 'src/app/transloco-root.module';

import { UserPermissionsService } from '../../services/user-permissions/user-permissions.service';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    CxHeaderModule,
    CxDialogModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    TranslocoRootModule,
    CxMenuModule,
    CxProfileDropdownComponent
  ],
  exports: [HeaderComponent],
  providers: [UserPermissionsService]
})
export class HeaderModule {}
