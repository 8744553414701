<ng-container *transloco="let t">
  <app-header [title]="t('overview.title')"></app-header>
</ng-container>

<div
  class="max-width ml-auto mr-auto error-pages-container d-flex align-items-center justify-content-center flex-column py-m"
  data-cy="error-not-found-container"
  *transloco="let t"
>
  <cx-message
    class="message-no-height"
    *transloco="let err; read: 'errors.forbidden'"
  >
    <cx-message-title>{{ err('title') }}</cx-message-title>
    <cx-message-image [imageSrc]="'assets/images/error-page-forbidden.svg'" />
    <cx-message-text class="message">
      <div class="text-center">
        {{ err('description') }}
        <br />
        <br />
        {{ err('reExplanation') }}
        <br />
        <br />
        {{ err('remark') }}
      </div>
    </cx-message-text>
  </cx-message>

  <div class="d-flex justify-content-center mb-m">
    <a
      mat-flat-button
      matTooltipPosition="above"
      color="accent"
      href="https://jira.bbraun.com/servicedesk/customer/portal/40"
      target="_self"
      rel="noopener noreferrer"
      [attr.aria-label]="t('deviceEdit.saveModuleEditDisabledLabel')"
      class="float-right disabled"
      data-cy="save-module-edit-button-disabled"
    >
      {{ t('errors.forbidden.createAuthorizationRequest') }}
    </a>
  </div>
  <div class="d-flex justify-content-center">
    <a
      data-cy="create-authorization-request"
      [title]="t('errors.loginDifferentAccount')"
      target="_self"
      class="link small-link d-inline-block"
      (click)="goToLogout()"
    >
      {{ t('errors.loginDifferentAccount') }}
    </a>
  </div>
</div>
