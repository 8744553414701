<cx-header *transloco="let t">
  <cx-header-back-navigator
    *ngIf="showBackNavigator"
    (click)="navigateBackwards()"
  ></cx-header-back-navigator>

  <cx-header-title data-cy="header-title">{{ title }}</cx-header-title>

  <cx-header-quickbar class="ml-sm">
    <button
      [matTooltip]="t('header.homeTooltip')"
      [attr.aria-label]="t('header.homeLabel')"
      data-cy="home-page"
      class="cx-header-white-icon mr-m"
      mat-icon-button
      (click)="navigateToHome()"
    >
      <mat-icon class="white-medium-icon">home</mat-icon>
    </button>
    <ng-container *ngIf="showAdminPanel">
      <button
        [matTooltip]="t('header.helpCenterTooltip')"
        [attr.aria-label]="t('header.helpCenterLabel')"
        data-cy="help-page"
        class="cx-header-white-icon mr-m"
        mat-icon-button
        (click)="navigateToHelpCenter()"
      >
        <mat-icon class="white-medium-icon">help</mat-icon>
      </button>
      <button
        [matTooltip]="t('header.adminPanelTooltip')"
        [attr.aria-label]="t('header.adminPanelLabel')"
        data-cy="admin-panel"
        class="cx-header-white-icon mr-m"
        mat-icon-button
        (click)="navigateToAdminPanel()"
      >
        <mat-icon class="white-medium-icon">admin_panel_settings</mat-icon>
      </button>
    </ng-container>

    <cx-profile-dropdown
      [firstname]="user.firstName!"
      [lastname]="user.lastName!"
      [email]="user.email!"
      data-cy="profile-dropdown-component"
      (goToProfile)="navigateToGPP()"
      (logout)="openLogOutDialog()"
    ></cx-profile-dropdown>
  </cx-header-quickbar>
</cx-header>
