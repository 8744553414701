export enum ErrorCodes {
  SE_DEV_001_400 = 'SE-DEV-001-400',
  SE_SCH_001_404 = 'SE-SCH-001-404',
  UE_FTR_001_400 = 'UE-FTR-001-400',
  SE_FTR_001_400 = 'SE-FTR-001-400',
  SE_FTR_002_400 = 'SE-FTR-002-400',
  SE_FTR_003_400 = 'SE-FTR-003-400',
  SE_FTR_004_400 = 'SE-FTR-004-400',
  SE_FTR_005_400 = 'SE-FTR-005-400',
  SE_FTR_006_400 = 'SE-FTR-006-400',
  SE_FTR_007_400 = 'SE-FTR-007-400',
  SE_FTR_008_400 = 'SE-FTR-008-400',
  SE_FTR_009_400 = 'SE-FTR-009-400',
  SE_FTR_010_400 = 'SE-FTR-010-400',
  SE_LLG_001_404 = 'SE-LLG-001-404',
  SE_GEN_001_400 = 'SE-GEN-001-400',
  SE_GEN_002_404 = 'SE-GEN-002-404',
  SE_FTR_011_409 = 'SE-FTR-011-409',
  SE_GEN_003_422 = 'SE-GEN-003-422',
  SE_GEN_004_500 = 'SE-GEN-004-500'
}
