import { HttpErrorResponse } from '@angular/common/http';

import { HTTP_STATUS_CODES } from '@bbraun/cortex/number-input';

import { ErrorCodes } from '../../enums/error-codes.enum';

export const isInternalServerError = (error: HttpErrorResponse): boolean =>
  error.status.toString().startsWith('5') || (error.status === 0 && !error.ok);

export const isBadRequestError = (error: HttpErrorResponse): boolean =>
  error.status === HTTP_STATUS_CODES.BAD_REQUEST &&
  error.error.errorCode !== ErrorCodes.UE_FTR_001_400;

// @TODO: Restore this when custom parameters int he error messages are needed
/*export const toErrorCodes = (value: string): unknown => value.replace(/-/g, '_').toUpperCase();

export const extractErrorMsgParams = (error: string): IterableIterator<RegExpMatchArray> =>
  error.matchAll(/\[(.*?)\]/g);

export const getErrorMsgParams = (error: string): Array<any> => {
  const iterableIterator: IterableIterator<RegExpMatchArray> = extractErrorMsgParams(error);
  const matches = [];
  for (const match of iterableIterator) {
    matches.push(match);
  }
  return matches.map((match) => match[1] ?? null);
};*/
/*
export const handleParams = (params: Array<string>): any =>
  params.reduce((obj, item, index) => ({ ...obj, ['param' + index]: item }), {});
*/
