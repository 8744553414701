import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService
} from '@azure/msal-angular';
import { CxDevToolsModule } from '@bbraun/cortex/dev-tools';
import { getStorageItem } from '@bbraun/helpex/src/lib/utils';
import { TRANSLOCO_CONFIG, translocoConfig } from '@jsverse/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderModule } from './modules/shared/components/header/header.module';
import {
  AVAILABLE_LANGUAGES,
  DEFAULT_LANGUAGE
} from './modules/shared/constants/language/languages.contants';
import { LOCAL_STORAGE } from './modules/shared/constants/local-storage/local-storage.constants';
import { HttpErrorInterceptor } from './modules/shared/interceptors/http-error/http-error.interceptor';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory
} from './modules/shared/services/user/authenticate/auth-config';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslocoRootModule,
    HeaderModule,
    HttpClientModule,
    CxDevToolsModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25 // Retains last 25 states
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalGuard,
    MsalService,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        defaultLang: getStorageItem(LOCAL_STORAGE.LANGUAGE) ?? DEFAULT_LANGUAGE,
        fallbackLang: DEFAULT_LANGUAGE,
        availableLangs: AVAILABLE_LANGUAGES,
        reRenderOnLangChange: true,
        missingHandler: {
          useFallbackTranslation: true
        }
      })
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private readonly registry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer
  ) {
    this.setupCustomIcons();
  }

  private setupCustomIcons(): void {
    const icons = [
      { name: 'license_changed_24_min', url: 'license_changed_24_min.svg' },
      { name: 'license_not_changed_24_min', url: 'license_not_changed_24_min.svg' }
    ].map((icon) => ({ name: icon.name, url: 'assets/images/icons/' + icon.url }));

    for (const icon of icons) {
      this.registry.addSvgIcon(icon.name, this.sanitizer.bypassSecurityTrustResourceUrl(icon.url));
    }
  }
}
