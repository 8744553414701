import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { CxDialogService } from '@bbraun/cortex/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { filter, take } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { DEFAULT_LANGUAGE, LANGUAGES } from '../../constants/language/languages.contants';
import { ROUTES } from '../../constants/routes/routes.constants';
import { UserData } from '../../interfaces/users/user-data.interface';
import { AuthenticateService } from '../../services/user/authenticate/authenticate.service';
import { AuthorizeService } from '../../services/user/authorize/authorize.service';
import { checkIsAdmin } from '../../utils/user/user-data-helper.utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @Input() title = '';

  @Input() showBackNavigator = false;

  @Output() navigateBack = new EventEmitter<void>();

  currentLang = DEFAULT_LANGUAGE;

  showAdminPanel = false;

  user: UserData = <any>{
    firstName: '',
    lastName: '',
    email: ''
  };

  constructor(
    private readonly authenticateService: AuthenticateService,
    private readonly authorizeService: AuthorizeService,
    private readonly translocoService: TranslocoService,
    private readonly dialogService: CxDialogService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.handleCurrentUserData();
  }

  openLogOutDialog(): void {
    this.dialogService
      .openConfirmDialog(
        this.translocoService.translate('header.logOutDialog.title'),
        this.translocoService.translate('header.logOutDialog.description'),
        this.translocoService.translate('header.logOutDialog.confirmButton'),
        this.translocoService.translate('header.logOutDialog.cancelButton')
      )
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (!result) {
          return;
        }

        this.authenticateService.logout();
      });
  }

  navigateBackwards(): void {
    this.navigateBack.emit();
  }

  navigateToHelpCenter(): void {
    this.router.navigateByUrl(ROUTES.HELP_CENTER);
  }

  navigateToAdminPanel(): void {
    this.router.navigateByUrl(ROUTES.ADMIN_PANEL);
  }

  navigateToGPP(): void {
    window.open(environment.gppUrl, '_blank')?.focus();
  }

  navigateToHome(): void {
    this.router.navigateByUrl(ROUTES.HOME);
  }

  handleCurrentUserData(): void {
    this.authorizeService.currentUser$.pipe(filter((user) => user != null)).subscribe((user) => {
      this.showAdminPanel = checkIsAdmin(user!.role);
      this.currentLang =
        LANGUAGES.find((lang) => lang.key === user?.language || '')?.key || DEFAULT_LANGUAGE;
      this.translocoService.setActiveLang(this.currentLang);
      this.user =
        user ||
        <any>{
          firstName: '',
          lastName: '',
          email: ''
        };
    });
  }
}
