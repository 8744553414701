import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { setStorageItem } from '@bbraun/helpex/src/lib/utils';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject, concatMap, filter, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { DEFAULT_LANGUAGE, LANGUAGES } from '../../../constants/language/languages.contants';
import { LOCAL_STORAGE } from '../../../constants/local-storage/local-storage.constants';
import { UserData } from '../../../interfaces/users/user-data.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeService {
  private currentUserSubject$ = new BehaviorSubject<UserData | null>(null);
  private _currentUser$: Observable<UserData | null> = this.currentUserSubject$.asObservable();

  set currentUser$(user: UserData | null) {
    this.currentUserSubject$.next(user);
  }

  get currentUser$(): Observable<UserData | null> {
    return this._currentUser$;
  }

  constructor(
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly http: HttpClient,
    private readonly translocoService: TranslocoService,
    private readonly msalService: MsalService
  ) {}

  handleUserAuthorization() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status) => {
          if (status !== InteractionStatus.None) {
            return false;
          }
          const activeAccount: AccountInfo | null = this.msalService.instance.getActiveAccount();
          return activeAccount != null ? true : false;
        }),
        concatMap(() => this.getCurrentUserInfo())
      )
      .subscribe((currentUser: UserData) => this.setCurrentUserData(currentUser));
  }

  private getCurrentUserInfo(): Observable<UserData> {
    return this.http.get<UserData>(`${environment.api}/users/current/info`);
  }

  private setCurrentUserData(currentUser: UserData): void {
    this.currentUser$ = currentUser;
    const lang = LANGUAGES.find((lang) => lang.key === currentUser?.language || '');
    this.translocoService.setActiveLang(lang?.key || DEFAULT_LANGUAGE);
    setStorageItem(LOCAL_STORAGE.LANGUAGE, lang?.key || DEFAULT_LANGUAGE);
  }
}
