import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ROUTES } from '../../constants/routes/routes.constants';
import { AuthorizeService } from '../../services/user/authorize/authorize.service';
import { checkIsAdmin } from '../../utils/user/user-data-helper.utils';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private readonly authorizeService: AuthorizeService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authorizeService.currentUser$.pipe(
      filter((user) => user != null),
      map((user) => {
        if (checkIsAdmin(user!.role)) {
          return true;
        }
        this.router.navigateByUrl(ROUTES.ERROR.FORBIDDEN);
        return false;
      })
    );
  }
}
