import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-page-internal-server-error',
  templateUrl: './internal-server-error.component.html',
  styleUrls: ['./internal-server-error.component.scss']
})
export class PageInternalServerErrorComponent implements OnInit {
  hideBtn = false;
  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.hideBtn = window.location.pathname === '/';
  }

  goToHome(): void {
    this.router.navigateByUrl(``);
  }
}
