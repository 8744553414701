import { UserRoles } from '../../enums/user-roles.enum';

export const checkIsAdmin = (role: UserRoles): boolean => {
  return role === UserRoles.SUPER_ADMIN || role === UserRoles.ADMIN;
};

export const checkIsSales = (role: UserRoles): boolean => {
  return role === UserRoles.LOCAL_SALES || role === UserRoles.GLOBAL_SALES;
};

export const checkIsLocalSales = (role: UserRoles): boolean => {
  return role === UserRoles.LOCAL_SALES;
};

export const checkIsGlobalSalesOrTechnician = (role: UserRoles): boolean => {
  return role === UserRoles.GLOBAL_SALES || role == UserRoles.GLOBAL_TECHNICIAN;
};
