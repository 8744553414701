import { HttpClient } from '@angular/common/http';
import { inject, Injectable, NgModule } from '@angular/core';

import {
  provideTransloco,
  Translation,
  TranslocoLoader,
  TranslocoModule
} from '@jsverse/transloco';

import {
  AVAILABLE_LANGUAGES,
  DEFAULT_LANGUAGE
} from './modules/shared/constants/language/languages.contants';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: AVAILABLE_LANGUAGES,
        defaultLang: DEFAULT_LANGUAGE,
        fallbackLang: DEFAULT_LANGUAGE,
        reRenderOnLangChange: true,
        prodMode: true,
        missingHandler: {
          useFallbackTranslation: true
        }
      },
      loader: TranslocoHttpLoader
    })
  ]
})
export class TranslocoRootModule {}
