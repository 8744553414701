import { Component } from '@angular/core';

import { AuthenticateService } from '../../../services/user/authenticate/authenticate.service';

@Component({
  selector: 'app-error-page-forbidden',
  templateUrl: './error-page-forbidden.component.html',
  styleUrls: ['./error-page-forbidden.component.scss']
})
export class ErrorPageForbiddenComponent {
  constructor(private readonly authenticateService: AuthenticateService) {}

  goToLogout(): void {
    this.authenticateService.logout();
  }
}
