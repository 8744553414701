import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

import { ErrorPageForbiddenComponent } from './modules/shared/components/error-pages/forbidden/error-page-forbidden.component';
import { PageInternalServerErrorComponent } from './modules/shared/components/error-pages/internal-server-error/internal-server-error.component';
import { ErrorPageNotFoundComponent } from './modules/shared/components/error-pages/not-found/error-page-not-found.component';
import { ROUTES } from './modules/shared/constants/routes/routes.constants';
import { AuthGuard } from './modules/shared/guards/auth/auth.guard';

const routes: Routes = [
  {
    path: ROUTES.HOME,
    loadChildren: () => import('./modules/overview/overview.module').then((m) => m.OverviewModule),
    canActivate: [MsalGuard]
  },
  {
    path: ROUTES.DEVICE_DETAILS,
    loadChildren: () =>
      import('./modules/device-details/device-details.module').then((m) => m.DeviceDetailsModule),
    canActivate: [MsalGuard]
  },
  {
    path: ROUTES.OFFLINE_MANAGEMENT,
    loadChildren: () =>
      import('./modules/offline-management/offline-management.module').then(
        (m) => m.OfflineManagementModule
      ),
    canActivate: [MsalGuard]
  },
  {
    path: ROUTES.OVERVIEW_HISTORY,
    loadChildren: () =>
      import('./modules/history-overview/history-overview.module').then(
        (m) => m.HistoryOverviewModule
      ),
    canActivate: [MsalGuard]
  },
  {
    path: ROUTES.HISTORY_DETAILS,
    loadChildren: () =>
      import('./modules/history-details/history-details.module').then(
        (m) => m.HistoryDetailsModule
      ),
    canActivate: [MsalGuard]
  },
  {
    path: ROUTES.HELP_CENTER,
    loadChildren: () =>
      import('./modules/help-center/help-center.module').then((m) => m.HelpCenterModule),
    canActivate: [MsalGuard, AuthGuard]
  },
  {
    path: ROUTES.ADMIN_PANEL,
    loadChildren: () =>
      import('./modules/admin-panel/admin-panel.module').then((m) => m.AdminPanelModule),
    canActivate: [MsalGuard, AuthGuard]
  },
  {
    path: ROUTES.USER_PERMISSIONS,
    loadChildren: () =>
      import('./modules/user-permissions/user-permissions.module').then(
        (m) => m.UserPermissionsModule
      ),
    canActivate: [MsalGuard, AuthGuard]
  },
  {
    path: ROUTES.MANAGE_PERMISSIONS,
    loadChildren: () =>
      import('./modules/manage-permissions/manage-permissions.module').then(
        (m) => m.ManagePermissionsModule
      ),
    canActivate: [MsalGuard]
  },
  {
    path: ROUTES.LICENSE_AVAILABILITY,
    loadChildren: () =>
      import('./modules/license-availability/license-availability.module').then(
        (m) => m.LicenseAvailabilityModule
      ),
    canActivate: [MsalGuard]
  },
  {
    path: ROUTES.ERROR.NOT_FOUND,
    loadChildren: () =>
      import('./modules/shared/components/error-pages/error-pages.module').then(
        (m) => m.ErrorPageModule
      ),
    component: ErrorPageNotFoundComponent
  },
  {
    path: ROUTES.ERROR.FORBIDDEN,
    loadChildren: () =>
      import('./modules/shared/components/error-pages/error-pages.module').then(
        (m) => m.ErrorPageModule
      ),
    component: ErrorPageForbiddenComponent
  },
  {
    path: ROUTES.ERROR.SERVER_ERROR,
    loadChildren: () =>
      import('./modules/shared/components/error-pages/error-pages.module').then(
        (m) => m.ErrorPageModule
      ),
    component: PageInternalServerErrorComponent
  },
  { path: 'auth/callback', redirectTo: ROUTES.HOME },
  { path: '**', redirectTo: ROUTES.ERROR.NOT_FOUND }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, <any>{
      // The initial navigation is not performed. The location listener is set up before the root component gets created
      // allowing the auth logic to be initialized.
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled',
      relativeLinkResolution: 'legacy',
      // Restores the previous scroll position on backward navigation, else sets the position to the anchor if one is
      // provided, or sets the scroll position to [0, 0] (forward navigation).
      scrollPositionRestoration: 'enabled',
      canceledNavigationResolution: 'computed'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
